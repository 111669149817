import React from 'react'
import FAQ from '../components/FAQ'
import { Footer } from '../components/Footer'

export const QuestionsPage = () => {
  return <div>
  <div className='px-3'>
  <FAQ/>
  </div>
  <Footer />
  </div>
}
