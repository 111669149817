import React from 'react'
import { Space, Dropdown } from 'antd'

export const TelegramButton = () => {
    const items = [
        {
          label: <a  href="https://t.me/obmen_kremenchuk/#" target="_blank" rel="noopener noreferrer">оператор 1</a>,
          key: '0',
        },
        {
          label: <a href="https://t.me/obmenkremenchuk/#" target="_blank" rel="noopener noreferrer">оператор 2</a>,
          key: '1',
        },
      ];

  return (
    <div className='pb-3 lg:flex'>
        <Dropdown
        menu={{
            items,
            }}
        trigger={['click']}>
            <a href='/' onClick={(e) => e.preventDefault()}>
        <Space>
        <a className='flex ml-1' href='/'>
            <img src="/img/tg_ic.png" alt="telegram_icon" className='w-8 2xl:w-9' />
            <p className='pt-2 pl-2'><b> Зв'язатися з оператором у Telegram</b></p></a>
        </Space>
        </a>
        </Dropdown>
    </div>
  )
}
