import { LayoutCover } from "./components/LayoutCover"
import { Routes, Route } from 'react-router-dom'
import { MainPage } from "./pages/MainPage"
import { ExchangeRatePage } from "./pages/ExchangeRatePage"
import { ServicesPage } from "./pages/ServicesPage"
import { QuestionsPage } from "./pages/QuestionsPage"
import { ContactsPage } from "./pages/ContactsPage"

function App() {
  return (
    <LayoutCover>
      <Routes>
        <Route path='/' element={ <MainPage /> }/>
        <Route path='exchange-rate' element={ <ExchangeRatePage /> }/>
        <Route path='services' element={ <ServicesPage /> }/>
        <Route path='questions' element={ <QuestionsPage /> }/>
        <Route path='contacts' element={ <ContactsPage /> }/>
      </Routes>
    </LayoutCover>
  )
}

export default App;
