import React, {useEffect, useState} from 'react'
import { TablesSelector } from '../components/TablesSelector.jsx';
import { CurrencyExchangeForm } from '../components/exchangeForm/CurrencyExchangeForm.jsx';
import { Contacts } from '../components/contacts/Contacts.jsx'
import { DamageDollars } from '../components/DamageDollars.jsx';
import { TelegramChanelSubscribe } from '../components/TelegramChanelSubscribe';
import { Footer } from '../components/Footer';
import { TextSection } from '../components/TextSection';
import { Coments } from '../components/Coments';
import { Improveus } from '../components/Improveus';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const MainPage = () => {
  
  const city = useSelector((state) => state.currency.city)

  function cityFormatter(city) {
    return city == 'gp' ? "ГОРІШНІ ПЛАВНІ" : "КРЕМЕНЧУК  "
  } 
  
 
  return ( 
    <div className='content-center text-center '>
      
      <div className='xl:flex justify-between content-center text-right mb-5' >
   <div ></div>
   <p className='p-3 mb-3 bg-yellow-300 rounded-xl content-center text-center xl:w-6/12 text-2xl' > <b>ОБМІН ВАЛЮТ В МІСТІ {cityFormatter(city)}</b></p>
        <div></div>
      </div>
      <div className='lg:flex justify-between items-start'>
        <div className=' pt-0 order-2 md:order-1'>
        <TablesSelector />
        </div>
        <div className='order-1 md:order-2 mt-4 lg:mt-0'>
        <CurrencyExchangeForm />
        </div>
      </div>
      <p className='p-3  my-9 bg-yellow-300 rounded-xl text-sm content-center text-center' > <b>НАШІ ВІДДІЛЕННЯ ЗНАХОДЯТЬСЯ В РІЗНИХ ЧАСТИНАХ МІСТА. ДІЗНАТИСЯ НАЙБЛИЖЧУ АДРЕСУ ВИ МОЖЕТЕ ЗА ТЕЛЕФОНОМ АБО В ОСОБИСТИХ ПОВІДОМЛЕННЯХ</b></p>
      {/* <DamageDollars /> */}
      <TelegramChanelSubscribe />
      <TextSection/>
      <Coments/>
      <Improveus/>
      <Footer />
    </div>
  );
}
