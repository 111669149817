import React from 'react'
import { CurrencyExchangeForm } from '../components/exchangeForm/CurrencyExchangeForm'
import { Contacts } from '../components/contacts/Contacts'
import { Footer } from '../components/Footer'
import { TablesSelector } from '../components/TablesSelector'
import { TelegramChanelSubscribe } from '../components/TelegramChanelSubscribe'
import { TextSection } from '../components/TextSection'
import { Coments } from '../components/Coments'
import { Improveus } from '../components/Improveus'
import { useSelector } from 'react-redux';

export const ExchangeRatePage = () => {


  const city = useSelector((state) => state.currency.city)

  function cityFormatter(city) {
    return city == 'gp' ? "ГОРІШНІ ПЛАВНІ" : "КРЕМЕНЧУК  "
  }
  

  return (
    <div className='content-center text-center '>
      
      <div className='xl:flex justify-between content-center text-right mb-5' >
   <div ></div>
   <p className='p-3  mb-3 bg-yellow-300 rounded-xl content-center text-center xl:w-6/12 text-2xl' > <b>ОБМІН ВАЛЮТ В МІСТІ {cityFormatter(city)}</b></p>
        <div></div>
      </div>
      <div className='lg:flex justify-between items-start'>
      <div className='order-2  mt-4 lg:mt-0'>
        <CurrencyExchangeForm />
        </div>
        <div className=' pt-0 mt-4 lg:mt-0 order-1' >
        <TablesSelector />
        </div>
        
      </div>
      <p className='p-3  my-9 bg-yellow-300 rounded-xl text-sm content-center text-center' > <b>НАШІ ВІДДІЛЕННЯ ЗНАХОДЯТЬСЯ В РІЗНИХ ЧАСТИНАХ МІСТА. ДІЗНАТИСЯ НАЙБЛИЖЧУ АДРЕСУ ВИ МОЖЕТЕ ЗА ТЕЛЕФОНОМ АБО В ОСОБИСТИХ ПОВІДОМЛЕННЯХ</b></p>
      
      <Footer />
    </div>
  );
}
