import React from 'react';
import { Collapse, Button } from 'antd';

const { Panel } = Collapse;

const FAQ = () => {
  return ( <div className='mb-9'>
    <Collapse className='mt-3  content-center bg-yellow-300' accordion expandIconPosition='end'>
      <Panel className='text-xl' header="Де ви знаходитеся?" key="1">
      <p className='text-lg' >- Наші відділення знаходиться в усіх частинах міста. Дізнатися найближчий пункт обміну ви можете, зателефонувавши нам. </p>
      </Panel>
    </Collapse>

    <Collapse className='mt-4 content-center bg-yellow-100' accordion expandIconPosition='end'>
      <Panel className='text-xl' header="Як зафіксувати операцію?" key="1">
      <p className='text-lg' >- Скористайтесь послугою бронювання тут 
      <a href='/exchange-rate' className='text-center content-center' ><Button className='md:h-10 md:w-36 text-center content-center mx-4' type='primary' danger><b className='text-xs text-center content-center'>ЗАБРОНЮВАТИ</b></Button></a>

       або зателефонуйте нам і ми зафіксуємо валюту на 1 годину.</p>
      </Panel>
    </Collapse>
    <Collapse className='mt-4 content-center bg-yellow-300' accordion expandIconPosition='end'>
      <Panel className='text-xl' header="Чи можна принести свої кошти та перевірити їх на справжність?" key="1">
        <p className='text-lg' >- Так, ми можемо перевірити та перерахувати ваші кошти. Діє тариф 2 грн за купюру. </p>
      </Panel>
    </Collapse>
    {/* <Collapse className='mt-4 content-center bg-yellow-100' accordion expandIconPosition='end'>
      <Panel className='text-xl' header="Чи приймаєте ви зношені купюри?" key="1">
      <p className='text-lg' >- Зношені купюри приймаємо з мінімальною комісією. Точний відсоток можна дізнатися на касі, після того, як касир оцінить ступінь пошкодження.</p>
      </Panel>
    </Collapse> */}
    <Collapse className='mt-4 content-center bg-yellow-100' accordion expandIconPosition='end' >
      <Panel className='text-xl' header="Крос курс?" key="1">
      <p className='text-lg' >- Це коефіцієнт співвідношення курсу євро до курсу долара. У нас ви можете купувати $ за €, або € за $.</p>
      </Panel>
    </Collapse>
    </div>
  );
};

export default FAQ;
