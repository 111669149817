import React from 'react'
import {Swiper, SwiperSlide} from "swiper/react"

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import { IonIcon } from '@ionic/react';
import { arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import { Button } from 'antd';


export const Coments = () => {
  return (
    <div>
        <div >
       <div className='mb-2  mt-7 p-4 rounded-xl content-center bg-yellow-200 text-center 2xl:px-40 '>
            <p className=' p-3 text-xl lg:text-2xl xl:text-2xl  2xl:text-2xl content-center text-center'><b>Наші відгуки</b></p>
        </div>
    </div>

    <div className='mb-10 xl:flex mt-7 p-4 rounded-xl content-center text-center 2xl:px-40 '>



        <Swiper
           effect={''}
           grabCursor={true}
           centeredSlides={true}
           loop={true}
           slidesPerView={'auto'}
           coverflowEffect={{
             rotate: 0,
             stretch: 0,
             depth: 0,
             modifier: 2.5,
           }}
           pagination={{ el: '.swiper-pagination', clickable: true }}
           navigation={{
             nextEl: '.swiper-button-next',
             prevEl: '.swiper-button-prev',
             clickable: true,
           }}
           modules={[EffectCoverflow, Pagination, Navigation]}
           style={{
            "--swiper-pagination-color": "#E93424",
            "--swiper-navigation-color": "#E93424",
          }}
           className="swiper_container content-center"
        >
            <SwiperSlide className=''>
                <div className="justify-center flex">
                <img className='rounded-xl md:w-1/2' src="/img/coments/5.JPEG" alt="comment" />
                </div>
                
            </SwiperSlide>
            <SwiperSlide>
            <div className="justify-center flex">
                <img className='rounded-xl md:w-1/2' src="/img/coments/2.JPEG" alt="comment" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="justify-center flex">
                <img className='rounded-xl md:w-1/2' src="/img/coments/3.JPEG" alt="comment" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="justify-center flex">
                <img className='rounded-xl md:w-1/2' src="/img/coments/4.JPEG" alt="comment" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="justify-center flex">
                <img className='rounded-xl md:w-1/2' src="/img/coments/1.JPEG" alt="comment" />
                </div>
            </SwiperSlide> 

            
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <IonIcon icon={arrowBackOutline} name="arrow-back-outline" style={{ color: 'red' }} />
          </div>
          <div className="swiper-button-next slider-arrow">
            <IonIcon icon={arrowForwardOutline} name="arrow-forward-outline" style={{ color: 'red' }} />
          </div>
          <div className="swiper-pagination"></div>
        </div>

        </Swiper>

    </div>
    </div>
  )
}
