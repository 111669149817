import { Button } from 'antd'
import React from 'react'
import { InstagramButton } from './contacts/InstagramButton'
import { TelegramButton } from './contacts/TelegramButton'
import { ViberButton } from './contacts/ViberButton'

export const Footer = () => {
  return (
    <div  className='lg:mt-12 xl:mt-16 2xl:mt-20 content-center text-center' >
        <div className='lg:flex content-center text-center bg-yellow-50  rounded-xl p-7'>
            <div className='lg:w-1/3 xl:w-1/2 content-center text-center'>
            <img src="/img/logo.png" alt="logo" className="md:pl-28 lg:pl-0 max-h-28" />
            <br/>
            <p className='lg:text-left pl-4 text-base' >Використання, копіювання та розповсюдження інформації без посилання на джерело заборонено і преслідуються законом.</p>

            </div>
            <div className='lg:w-2/3 content-center md:content-start text-left lg:pl-12 xl:pl-32 2xl:pl-60 md:pl-40 pt-9'>
            <TelegramButton />
            <ViberButton />
            <InstagramButton />
            </div>
            <div className='content-center text-center pt-9 lg:pr-12 2xl:pl-0 xl:pl-8'>
            <a href='/exchange-rate'><Button className='h-12 w-40 text-center content-center' type='primary' danger><b>ЗАБРОНЮВАТИ</b></Button></a>
            <div className='lg:w-40 text-sm text-center content-center'>
            <p className='text-lg pt-5' ><a href='tel:+380980245894'>+380 98 024 5894</a></p>
            <p className='text-lg pt-5' ><a href='tel:+380675409020'>+380 67 540 9020</a></p>
            </div>
            </div>
        </div>
        <p className='bg-yellow-100 p-2 rounded-xl text-xm my-2 text-center'>Всі права захищені © obmen-kremenchuk-2023</p>
    </div>
  )
}
