import { Space } from 'antd'
import React from 'react'

export const InstagramButton = () => {
  return (
    <div><Space>
        <a className='flex' href='https://instagram.com/obmenkremenchug' target="_blank" rel="noopener noreferrer">
            <img src="/img/instagram.png" alt="inst_icon" className='w-9 2xl:w-10' />
            <p className='pt-2 pl-1'><b>Підписатися на Instagram</b></p>
            </a>
        </Space>
    </div>
  )
}
