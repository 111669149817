import React from 'react'
import { Offices } from '../components/contacts/Offices'
import { OurContacts } from '../components/contacts/OurContacts'
import { Footer } from '../components/Footer'

export const ContactsPage = () => {
  return <div>      
      <div className='xl:flex justify-between mb-9'>
        <OurContacts />
        <Offices />
      </div>
      <Footer />
    </div>
}
