import React, { useState } from 'react'
import { Menu, Button, Drawer} from 'antd';
import { NavLink, useLocation } from 'react-router-dom'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { Contacts } from './contacts/Contacts';


export const NavBar = () => {
  const [openMenu, setopenMenu] = useState(false)

  return ( 
    <div className='sticky'> 
      <div style={{background: "rgba(248,248,248)", height:60, paddingLeft:12, paddingTop:12 }} className='menuIcon'>
        <MenuOutlined style={{color: 'red', fontSize:30,}} onClick={() => {setopenMenu(true)}}/>
      </div>
    <span className='headerMenu' >
    <AppMenu />
    </span>
    <Drawer
    closeIcon={<CloseOutlined style={{color: 'red', fontSize:30,}}/>}
    className='bg-yellow-100'
     placement='left'
     open={openMenu}
     onClose={() => {
      setopenMenu(false);
     }}
     closable={true} 
     style={{background: "rgba(254, 249, 195,1)"}}
     width={390}>
      <AppMenu isInline onClose={() => setopenMenu(false)}/>
    </Drawer>
    </div>
  )
}


function AppMenu({isInline=false, onClose}) {
  const location = useLocation();

  const handleMenuClick = () => {
    onClose && onClose();
  };


  return (
    <header className="pt-5 pb-5 sticky rounded-b-xl bg-yellow-100 md:bg-transparent md:pb-0">
    <div className=' md:flex justify-between items-center pr-12 pl-12 md:pr-0 md:pl-0'>
      <div className='logo'>
        <a href='/'><img src="/img/logo_main.png" alt="logo" className="max-h-32 ml-16   md:ml-0" /></a>
      </div>

      <Contacts />
        
      <Menu
        style={{border: "none"}}
        className='menu text-center content-center mb-10 md:mb-0 md:w-3/6'
        mode={ isInline?"inline":"horizontal"}
        defaultSelectedKeys={location.pathname}
        items={[
          {
              label: <NavLink className={'2xl:text-base lg:text-xs xl:text-sm'} to={'/'} onClick={handleMenuClick}>ГОЛОВНА</NavLink>,
              key: '/',
          },
          {
              label: <NavLink className={'2xl:text-base lg:text-xs xl:text-sm'} to={'/exchange-rate'} onClick={handleMenuClick}>КУРСИ ВАЛЮТ</NavLink>,
              key: '/exchange-rate',
          },
          {
              label: <NavLink className={'2xl:text-base lg:text-xs xl:text-sm'} to={'/services'} onClick={handleMenuClick}>ПОСЛУГИ</NavLink>,
              key: '/services',
          },
          {
              label: <NavLink className={'2xl:text-base lg:text-xs xl:text-sm'} to={'/questions'} onClick={handleMenuClick}>ЗАПИТАННЯ</NavLink>,
              key: '/questions',
          },
          {
              label: <NavLink className={'2xl:text-base lg:text-xs xl:text-sm'} to={'/contacts'}onClick={handleMenuClick}>КОНТАКТИ</NavLink>,
              key: '/contacts',
          },
        ]}
      />

      <a href='/exchange-rate' className=''><Button className='ml-8 h-16 w-48 text-lg  md:ml-0 md:h-8 md:w-32 md:text-sm  xl:h-12 xl:w-40 2xl:h-12 2xl:w-40 lg:h-9 lg:w-36' type='primary' danger><b>ЗАБРОНЮВАТИ</b></Button></a>
    </div>
  </header>
  )
}
