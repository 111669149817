import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

const initialState = {
    currencies: [],
    selectedCurrency: "null",
    exchangeRate: 10,
    inputValue: 100,
    loading: true,
    city: "null",
}

export const getAllCurrencies = createAsyncThunk('currency/getAllCurrencies', async (city, thunkAPI) => {
    try {
        const { data } = await axios.get('/currencies/'+city)
        return data
    } catch (error) {
        console.log(error)
    }
})

export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {},
    extraReducers: {
        [getAllCurrencies.pending]: (state) => {
            state.loading = true
        },
        [getAllCurrencies.fulfilled]: (state, action) => {
            state.loading = false
            state.currencies = action.payload
            state.selectedCurrency = action.payload[0].title
            state.city = action.payload[0].city
            state.exchangeRate = action.payload[0].sell_value.$numberDecimal
        },
        [getAllCurrencies.rejected]: (state) => {
            state.loading = false
        },
    },
})

export default currencySlice.reducer