import React from 'react'
import { Button } from 'antd'

export const TelegramChanelSubscribe = () => {
  return (
    <div className='mt-9 lg:mt-20 xl:mt-24 2xl:mt-28 content-center text-center'>
        <div className='md:flex rounded-xl content-centertext-center justify-between bg-tg-dollars'>
        <img className='order-1 md:order-2 md:w-1/2' src='/img/Rectangle.png' alt='phone_mock'></img>

            <div className='order-2 md:order-1 xl:pt-12 2xl:pt-32 lg:mt-24 md:pl-16 md:text-3xl content-center text-center md:w-1/2' >
            <p className='mb-3 2xl:text-3xl xl:text-3xl lg:text-2xl  md:text-xl  md:mt-16 lg:mt-0 content-center text-center'><b>Підпишіться на наш Telegram канал, щоб слідкувати за актуальними курсами</b></p>

            <a className='' href='https://t.me/obminkremenchuk' target="_blank" rel="noopener noreferrer"><Button className='mb-5 xl:mt-16 xl:h-20 xl:w-60 xl:text-2xl lg:mt-10 lg:h-16 lg:w-52 lg:text-xl' type='primary' danger><b>ПІДПИСАТИСЯ</b></Button></a>
           </div>
        </div>

        <div className='md:flex mt-7 p-4 rounded-xl content-center bg-yellow-300 text-center '>
        <p className='p-3 lg:pl-10 xl:pl-20 2xl:pl-40 lg:text-base xl:text-lg  2xl:text-xl content-center text-center'><b>Слідкувати за актуальним курсом в Горішніх Плавнях</b></p>
        <a href='https://t.me/obmingorishiplavni' target="_blank" rel="noopener noreferrer"><Button className='2xl:h-12 2xl:w-40 xl:h-12 xl:w-40 lg:h-10 lg:w-40 lg:ml-32 xl:ml-60 2xl:ml-72  md:mt-2 lg:mt-0' type='primary' danger><b>ПІДПИСАТИСЯ</b></Button></a>
            
        </div>
    </div>
  )
}
