import React from 'react'
import { Dropdown, Space } from 'antd';

export const ViberLogoDropDown = () => {
    const items = [
        {
          label: <a href="https://msng.link/o/?380980245894=vi" target="_blank" rel="noopener noreferrer">viber 1</a>,
          key: '0',
        },
        {
          label: <a href="https://msng.link/o/?380675409020=vi" target="_blank" rel="noopener noreferrer">viber 2</a>,
          key: '1',
        },
      ];
  return (
    <Dropdown
    menu={{
        items,
        }}
    trigger={['click']}>
        <a href='/' onClick={(e) => e.preventDefault()}>
        <Space>
            <img src="/img/viber.png" alt="viber_icon" className='' />
        </Space>
        </a>
    </Dropdown>
  )
}
