import React from 'react'
import Services from '../components/Services'
import { Footer } from '../components/Footer'

export const ServicesPage = () => {
  return <div >
    <div className='px-5'>
    <Services />
    </div>
    <Footer />
  </div>
}
