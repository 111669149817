import React from 'react'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

export const TelegramDropdown = () => {
    const items = [
        {
          label: <a  href="https://t.me/obmen_kremenchuk" target="_blank" rel="noopener noreferrer">оператор 1</a>,
          key: '0',
        },
        {
          label: <a href="https://t.me/obmenkremenchuk" target="_blank" rel="noopener noreferrer">оператор 2</a>,
          key: '1',
        },
      ];

  return (
   <Dropdown
        menu={{
            items,
            }}
        trigger={['click']}>
            <a href='/' onClick={(e) => e.preventDefault()}>
                <Space>
                Telegram
                <DownOutlined />
                </Space>
            </a>
    </Dropdown>
  )
}
