import React from 'react'
import { Button, Divider } from 'antd'
import { TelegramButtonLarge } from './TelegramButtonLarge'
import { ViberButtonLarge } from './ViberButtonLarge'
import { InstagramButtonLarge } from './InstagramButtonLarge'

export const OurContacts = () => {
  return (
    <div className='text-center xl:w-7/12 rounded-xl bg-yellow-50 p-5 content-center mb-5'  >
        <p className='text-2xl mt-5'><b>Наші контакти</b></p>
        <Divider />
        <div className='text-left  '>
            <div className='lg:flex justify-between lg:px-10'>
            <div className='content-center text-center mt-4'>
            <p className='text-xl lg:text-lg 2xl:text-xl mb-5' ><a href='tel:+380980245894'>+380 98 024 5894</a></p>
            <p className='text-xl lg:text-lg 2xl:text-xl' ><a href='tel:+380675409020'>+380 67 540 9020</a></p>
            </div>
            <div className='lg:ml-10 text-center content-center mt-5 md:mt-2 lg:text-left'>
            <TelegramButtonLarge />
            <ViberButtonLarge/>
            </div>
            </div>
            
            <p className='mt-7 text-xl mb-10 text-center content-center' ><b>Підпишіться на Telegram канали, щоб<br/>слідкувати за курсом валют</b></p>
            <div className='text-center content-center'>
            <a className='' href='https://t.me/obminkremenchuk' target="_blank" rel="noopener noreferrer"><Button className=' 2xl:ml-2 mb-4 h-12 md:w-2/3 text-lg' type='primary' danger><b>ПІДПИСАТИСЯ для міста Кременчук</b></Button></a>
            
            <a className='' href='https://t.me/obmingorishiplavni' target="_blank" rel="noopener noreferrer"><Button className='xl:ml-0  lg:ml-3 2xl:ml-2 mb-4 h-12 md:w-2/3 text-lg' type='primary' danger><b>ПІДПИСАТИСЯ для міста Горішні Плавні</b></Button></a>
            </div>
            <Divider />
            <div className='lg:ml-10 text-center content-center' >
            <InstagramButtonLarge />
            </div>
          </div>
          <br />
    </div>
  )
}
