import React from 'react'
import {EnvironmentTwoTone} from '@ant-design/icons';

export const Offices = () => {
  return (
    <div className='xl:w-2/5 text-center content-center rounded-xl bg-yellow-50 p-5 px-12 pt-12' >
          <p className='text-2xl'><b>Наші відділення</b></p>
          <div className='lg:flex justify-center' >
          <p className='sities p-3 lg:w-3/5  mt-12 bg-yellow-300 rounded-xl content-center text-center 2xl:text-xl xl:text-lg' ><EnvironmentTwoTone twoToneColor='#E93424' /> Кременчук</p><br />
          <p className='sities p-3 lg:w-3/5  lg:ml-3 lg:mt-12 bg-yellow-300 rounded-xl content-center text-center 2xl:text-xl xl:text-lg' ><EnvironmentTwoTone twoToneColor='#E93424' /> Горішні Плавні</p>
          </div>
          <p className='p-3 mt-9 px-9 bg-yellow-100 rounded-xl content-center lg:text-left text-base' >НАШІ ВІДДІЛЕННЯ ЗНАХОДЯТЬСЯ В РІЗНИХ ЧАСТИНАХ МІСТА.</p>
          <p className='p-3 mt-3 px-9 bg-yellow-100 rounded-xl content-center lg:text-left text-base' > ДІЗНАТИСЯ НАЙБЛИЖЧУ АДРЕСУ ВИ МОЖЕТЕ ЗА ТЕЛЕФОНОМ АБО В ОСОБИСТИХ ПОВІДОМЛЕННЯХ</p>

    </div>
  )
}
