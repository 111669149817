import React from 'react'
import { Button } from 'antd'

export const TextSection = () => {
  return (
    <div>
    <div className='mt-10 lg:mt-20 xl:mt-24 2xl:mt-28 content-center bg-yellow-50  text-center rounded-xl p-7 py-10'>
        <p className='text-xl text-center'><b>ОБМІН ВАЛЮТ В КРЕМЕНЧУЦІ ТА ГОРІШНІХ ПЛАВНЯХ. ЧОГО ВАРТО ОБРАТИ САМЕ НАС? </b></p>
        <p className='text-left text-lg mt-5'>
        <p className='text-center'>Ви постійно в пошуку вигідних курсів? Вам набридли постійні коливання валюти? Тоді вам до нас!</p>
        <br/>
        <div className='lg:flex justify-between md:px-20'>
        <ul className='lg:w-1/2'>
          <li className='p-3 bg-yellow-200 rounded-xl mb-2 flex'><p className='pt-2 md:pt-1 text-3xl mr-3'>💲</p><div className='pt-3 md:pt-2'><p><b>Найвигідніший</b> курс обміну в місті.</p></div> </li>
          <li className='p-3 bg-yellow-200 rounded-xl mb-2 flex'><p className='pt-2 md:pt-3 lg:pt-1 text-3xl mr-3'>🌍</p><div className='pt-0 md:pt-1'><p>У нас можна купити/продати <b>усі валюти світу</b>.</p></div> </li>
          <li className='p-3 bg-yellow-200 rounded-xl mb-2 flex'><p className='pt-6 lg:pt-2 text-3xl mr-3'>⏰</p><div><p>Зручний графік роботи.</p><p className=''>Ми працюємо кожного дня <b>з 08:00 до 19:00</b>.</p></div> </li>
          <li className='p-3 bg-yellow-200 rounded-xl mb-2 flex'><p className='pt-10 md:pt-8 lg:pt-2 xl:pt-6 2xl:pt-2 text-3xl mr-3'>✅</p><div><p>Можна не хвилюватися, що поки ви  доберетеся до нас, курс зміниться. Адже ми маємо послугу <b>ФІКСАЦІЇ на одну годину</b> </p></div> </li>
          <li className='p-3 bg-yellow-200 rounded-xl mb-2 flex'><p className='pt-0 md:pt-0 lg:pt-2 xl:pt-0 2xl:pt-0  text-3xl mr-3'>%</p><div><p>У нас вигідніше, ніж в інших обмінниках.</p></div> </li>
          <li className='p-3 bg-yellow-200 rounded-xl mb-2 flex'><p className='pt-10  md:pt-6 lg:pt-2 xl:pt-6 2xl:pt-2  text-3xl mr-3'>📍</p><div><p>Наші відділення розташовані <b>в усіх районах міста</b>. Ви можете обрати для себе найближче та здійснити операцію.</p></div> </li>
        </ul>
        <img className='lg:w-2/5 lg:mt-80 xl:mt-32 2xl:mt-8 lg:h-5/6 xl:w-3/6' src='/img/dollars.png' alt='dollars'></img>
        </div>
        </p>
    </div>
    </div>
  )
}
