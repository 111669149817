import React, { useState, useEffect }  from 'react'
import { Button } from 'antd';
import { Modal } from 'antd';
import { BasicTable } from './table/BasicTable';
import { SearchOutlined } from '@ant-design/icons';
import { TelegramButton } from './contacts/TelegramButton';

let timeoutId;

export const TablesSelector = () => {


    const [selectedCity, setSelectedCity] = useState('kremen');

    const [open, setOpen] = useState(false);


    useEffect(() => {
      clearTimeout(timeoutId);
      const timer = setTimeout(() => {
        showModal();
      }, 20000);
  
      return () => clearTimeout(timer);
    }, [selectedCity]);

    const handleCitySelect = (city) => {
        setSelectedCity(city);

    };


    const activeStyles = {
        background: '#F4F450',
        color: '#000000'
        
      }

      const showModal = () => {
        setOpen(true);
      };

      const handleCancel = () => {
        setOpen(false);
      };                                                                                                                                                                                                                                                  


  return (
    <div className=''>
        <div className='flex lg:justify-start  justify-center pt-0 mb-0'>
        <button style={selectedCity === 'kremen' ? activeStyles : {}} className='p-3 mb-1 2xl:mb-3 mr-5 text-gray-600  bg-yellow-100 rounded-xl content-center text-center' onClick={() => handleCitySelect('kremen')}><b>КРЕМЕНЧУК</b></button>
        <button style={selectedCity === 'gp' ? activeStyles : {}} className='p-3 mb-1 2xl:mb-3 text-gray-600 bg-yellow-100 rounded-xl content-center text-center' onClick={() => handleCitySelect('gp')}><b>ГОРІШНІ ПЛАВНІ</b></button>
        </div>
        <div className=' w-5/12 lg:w-max content-center text-center'>
        { selectedCity && <BasicTable city={selectedCity} />}
        </div>
        <Modal
        width={800}              
        open={open}
        title={<p className='text-red-600 text-3xl text-center pt-10 pb-6'>Підписатися на TELEGRAM!</p>}
        onCancel={handleCancel}
        footer={null}
      >
        <p className='text-center pb-5'>Отримуйте актуальну інформацію про курси валют у себе в смартфоні </p>
        <div className='text-center lg:flex justify-center pb-10'>
        <Button
            key="link"
            target="_blank"
            rel="noopener noreferrer"
            href={"https://t.me/obminkremenchuk"}
            type="text"
            onClick={handleCancel}
           
          >
            {< div className='flex sm:text-xl'><img src="/img/tg_ic.png" alt="telegram_icon" className='lg:w-10 w-8 px-1' />Кременчук</div>}
          </Button>
          <Button
          key="link"
          target="_blank"
          rel="noopener noreferrer"
          href={ "https://t.me/obmingorishiplavni"}
          type="text"
          onClick={handleCancel}
         
        >
          {< div className='flex sm:text-xl'><img src="/img/tg_ic.png" alt="telegram_icon" className='lg:w-10 w-8 px-1' />Горішні Плавні</div>}
        </Button>
        </div>
      </Modal>
    </div>
  )
}
