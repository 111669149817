import React from 'react'
import { Form, Select, InputNumber, Button, Modal, Input, Spin } from 'antd'
import PhoneInput from "antd-phone-input";
import { TelegramDropdown } from '../contacts/TelegramDropdown.jsx';
import { useState, useEffect } from 'react';

import { notification, message } from 'antd';
import { useMemo } from 'react';
import { DollarCircleTwoTone, AlertTwoTone } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { createOrder } from '../../redux/features/order/orderSlice.js';
import { useDispatch } from 'react-redux'

const Context = React.createContext({
  name: 'Default',
});

export const CurrencyExchangeForm = () => {
  const [form] = Form.useForm();

  const currencies = useSelector((state) => state.currency)


  const [selectedCurrency, setSelectedCurrency] = useState("🇺🇸 USD");
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState("🇺🇦 UAH");
  const [curreniesPairs, setCurreniesPairs] = useState(["🇺🇦 UAH", "🇪🇺 EUR"]);
  const [options, setOptions] = useState([]);

  const [exchangeValue, setExchangeValue] = useState(currencies.exchangeRate)
  


  const dispatch = useDispatch()

  const [amount, setAmount] = useState(100);
  const [amountInFromCurrency, setAmountInFromCurrency] = useState(true);
  let toAmount, fromAmount;
  if (amountInFromCurrency) {
    fromAmount = amount;
    toAmount = (amount * exchangeValue).toFixed(2);
    form.setFieldValue("sell",  fromAmount)
    form.setFieldValue("buy",  toAmount)
  } else {
    toAmount = amount;
    fromAmount = (amount / exchangeValue).toFixed(2);
    form.setFieldValue("sell",  fromAmount)
    form.setFieldValue("buy",  toAmount)
  }

  const handleFromAmountChange = (event) => {
    setAmount(event);
    form.setFieldValue("sell",  event)
    console.log(fromAmount, toAmount)
    setAmountInFromCurrency(true);
  };

  const handleToAmountChange = (event) => {
    setAmount(event);
    form.setFieldValue("buy",  event)
    console.log(fromAmount, toAmount)
    setAmountInFromCurrency(false);
  };

  function getListOfCurrencies(currency, cur_pairs) {
    for (let i = 0; i < cur_pairs.length; i++) {
      const cur_pair = cur_pairs[i];
      const currencies = Object.keys(cur_pair)[0];
      
      if (currencies === currency) {
        return cur_pair[currencies];
      }
    }
    return null;
  }

  function getCurrencyValue(title1, title2, currencies) {
    for (let i = 0; i < currencies.length; i++) {
      const currency = currencies[i];
      if (currency.title_sell === title1 && currency.title_buy === title2) {
        return currency.sell_value.$numberDecimal;
      }
      if (currency.title_sell === title2 && currency.title_buy === title1) {
        return 1 / currency.buy_value.$numberDecimal;
      }
    }
    return null;
  }

  const cur_pairs = [
    {"🇺🇸 USD": ["🇺🇦 UAH", "🇪🇺 EUR"]},
    {"🇪🇺 EUR": ["🇺🇦 UAH", "🇺🇸 USD"]},
    {"🇵🇱 PLN": ["🇺🇦 UAH"]},
    {"🇬🇧 GBP": ["🇺🇦 UAH"]},
    {"🇨🇿 CZK": ["🇺🇦 UAH"]},
    {"🇨🇭 CHF": ["🇺🇦 UAH"]},
    {"🇺🇦 UAH": ["🇺🇸 USD", "🇪🇺 EUR", "🇵🇱 PLN", "🇬🇧 GBP", "🇨🇿 CZK", "🇨🇭 CHF"]},

  ]

  useEffect(() => {
    const newOptions = [];
    curreniesPairs.forEach((cur_pair) => {
      const currenciess = cur_pair;
      setSelectedCurrencyPair(curreniesPairs[0])
      setExchangeValue(getCurrencyValue(selectedCurrency, curreniesPairs[0], currencies.currencies))
    
      newOptions.push(
        <Option key={currenciess} value={currenciess}>
          {currenciess}
        </Option>
      );
    });
    setOptions(newOptions);
  }, [curreniesPairs]);


  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e);
    setCurreniesPairs(getListOfCurrencies(e, cur_pairs))
    setExchangeValue(getCurrencyValue(e, selectedCurrencyPair, currencies.currencies))
  };


  useEffect(() => {
    if (currencies.exchangeRate) { 
      setExchangeValue(parseFloat(currencies.exchangeRate))
    }
  }, [currencies.exchangeRate]);


  const handleCurrencyPairChange = (e) => {
    setSelectedCurrencyPair(e);
    setExchangeValue(getCurrencyValue(selectedCurrency, e, currencies.currencies))
  };








  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolder_sec] = message.useMessage();
  const openNotification = (placement) => {
    api.info({
      icon: <DollarCircleTwoTone twoToneColor='#E93424'  />,
      message: `Заявку надіслано`,
      description: <div id='thank-message'><Context.Consumer>{({ name }) => `${name}`}</Context.Consumer></div>,
      placement,
    });
  };
  const contextValue = useMemo(
    () => ({
      name: 'ДЯКУЄМО! Ми отримали заявку та зв’яжемося з вами протягом 10 хвилин',
    }),
    [],
  );




  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    if(!form.getFieldValue('name')) {
      if(form.getFieldValue('phone').phoneNumber == null) {
        messageApi.open({
          content: 'Введіть, будь ласка, свій номер телефону',
          icon: <AlertTwoTone twoToneColor='#E93424'  />,
        });
      }
      return;
    }
    if(form.getFieldValue('phone').phoneNumber == null) {
      messageApi.open({
        content: 'Введіть, будь ласка, свій номер телефону',
        icon: <AlertTwoTone twoToneColor='#E93424'  />,
      });
      return;
    }
    setConfirmLoading(true);
      setOpen(false);


      setConfirmLoading(false);

      try {

        const data = {
          "name": form.getFieldValue('name'),
          "phone": (form.getFieldValue('phone').countryCode).toString() + (form.getFieldValue('phone').areaCode).toString() + form.getFieldValue('phone').phoneNumber,
          "sell_currency": selectedCurrency,
          "buy_currency": selectedCurrencyPair,
          "sell_sum_value": fromAmount,
          "city": currencies.city
        }

        if(form.getFieldValue('promocode') != '') {
          data["promocode"] = form.getFieldValue('promocode')
        }

        dispatch(createOrder(data))

        openNotification('topRight');

      } catch (error) {
        console.log(error)
      }
  
     
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    form.resetFields();
    setOpen(false);
  };


  
  
  const { Option } = Select;
  

  const selectBefore_sell = (
    <Select
    onChange={handleCurrencyChange}
    className='select-sum'
    name="sell_currency"
    size='large'
      defaultValue="🇺🇸 USD"
      style={{
        background: 'white',
        width: 120,
        borderTopLeftRadius: '0.75rem',
        borderBottomLeftRadius: '0.75rem',
        
      }}
    >
          <Option key="USD" value="🇺🇸 USD">🇺🇸 USD</Option>
          <Option key="EUR" value="🇪🇺 EUR">🇪🇺 EUR</Option>
          <Option key="UAH" value="🇺🇦 UAH">🇺🇦 UAH</Option>
          <Option key="PLN" value="🇵🇱 PLN">🇵🇱 PLN</Option>
          <Option key="GBP" value="🇬🇧 GBP">🇬🇧 GBP</Option>
          <Option key="CZK" value="🇨🇿 CZK">🇨🇿 CZK</Option>
          <Option key="CHF" value="🇨🇭 CHF">🇨🇭 CHF</Option>
    </Select>
  );

  const selectBefore_buy = (
    <Select
    onChange={handleCurrencyPairChange}
    className='select-sum'
    name="buy_currency"
    size='large'
      value={selectedCurrencyPair}
      style={{
        background: 'white',
        width: 120,
        borderTopLeftRadius: '0.75rem',
        borderBottomLeftRadius: '0.75rem',
        
      }}
    >
      {options}
    </Select>
  );

  return (
    currencies.loading ? (
      <Spin className='content-center text-center md:w-1/2' />
    ) :
    <Context.Provider value={contextValue}>
    {contextHolder}
    {contextHolder_sec}
    <div className=''>
      <h3 className='p-3 mb-3  bg-yellow-100 rounded-xl content-center text-center' ><b>ЗАБРОНЮВАТИ ВАЛЮТУ</b> на 1 годину</h3>
      <div className='bg-yellow-50 rounded-xl'>
        <Form
        id="myForm" 
        style={{background: '#F4F450',}} 
        className='xl:px-16 2xl:px-32 py-10  lg:px-4 rounded-xl content-center text-center'
        form={form} layout="vertical" autoComplete="off">
          
        <Form.Item className='m-2 mb-6 p-0' name="sell" label={<p className='  ml-10 sm:ml-36 md:ml-40 lg:ml-6'>ВІДДАЄТЕ</p>}> 
          <InputNumber
            min="1"
            value={fromAmount}
            onChange={handleFromAmountChange}
            className='input-sum' 
            size='large'
            addonBefore={selectBefore_sell}  />
        </Form.Item>
        <Form.Item className='m-2 p-0' name="buy" label={<p className=' ml-10 sm:ml-36 md:ml-40 lg:ml-6'>ОТРИМУЄТЕ</p>}>
           <InputNumber 
              value={toAmount}
              min="1"
              onChange={handleToAmountChange}
              style={{backgroundColor: 'white'}} 
              className='input-sum rounded-xl'  
              size='large' 
              addonBefore={selectBefore_buy} 
               />
        </Form.Item>
        <div className='content-start text-left'>
        <p className='mb-7 ml-14 pl-4 md:ml-40 lg:ml-3 sm:ml-36'><b>* Курс обміну {selectedCurrency} / {selectedCurrencyPair} = {parseFloat(exchangeValue).toFixed(2)}</b></p>
        </div>
        <Button className='h-12 w-44  2xl:h-16 2xl:w-60 xl:h-16 xl:w-60 lg:h-12 text-lg' type='primary' danger onClick={showModal} ><b>ЗАБРОНЮВАТИ</b></Button>
        <p className='p-3 bg-yellow-100 md:rounded-xl content-center text-center mt-4' >Курс діє від 500$ або в еквіваленті 500$</p>

        <Modal
        className='content-right text-left'
        title="Форма підтвердження"
        okText='ПІДТВЕРДИТИ'
        cancelText='ВІДМІНИТИ'
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{ form: "myForm", danger: 'true', htmlType: 'submit', type: 'primary', href: '#thank-you',
      }}
      >
        <Form.Item name="name" className='mt-10' 
        validateFirst={true}
        rules={[
          {
            required: true,
            message: "будь ласка, введіть своє ім'я",
          },
        ]}>
          <Input placeholder="Введіть своє ім'я" className='md:w-11/12'/>
        </Form.Item>
        <Form.Item name="phone" 
        
        validateFirst={true}
        rules={[
          {
            required: true,
            message: 'будь ласка, введіть свій номер телефону',
          },
        ]}>
          <PhoneInput className='' size='' 
           country='ua'
          />
        </Form.Item>
        <Form.Item name="promocode"  className='text-left' >
          <Input placeholder="Введіть промокод" className=' w-6/12' />
        </Form.Item>
      </Modal>
      </Form>

      </div>
      <p className='p-3  bg-yellow-100 mt-3 rounded-xl content-center text-center' >При бронюванні на суму від <b>10 000 USD</b> <br/>зверніться у відділ продажів <TelegramDropdown /></p>
    </div>
    </Context.Provider>
   
  )
}
