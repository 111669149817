import React from 'react'
import { Space, Dropdown } from 'antd'

export const ViberButton = () => {
    const items = [
        {
          label: <a href="https://msng.link/o/?380980245894=vi" target="_blank" rel="noopener noreferrer">viber 1</a>,
          key: '0',
        },
        {
          label: <a href="https://msng.link/o/?380675409020=vi" target="_blank" rel="noopener noreferrer">viber 2</a>,
          key: '1',
        },
      ];
  return (
    <div className='pl-1 pb-4'>
        <Dropdown
        menu={{
            items,
            }}
        trigger={['click']}>
            <a href='/' onClick={(e) => e.preventDefault()}>
        <Space>
        <a className='flex' href='/'>
            <img src="/img/viber.png" alt="viber_icon" className='w-8 2xl:w-9' />
            <p className='pt-1 pl-2'><b>Зв'язатися з оператором у Viber</b></p>
            </a>
        </Space>
        </a>
        </Dropdown>
    </div>
  )
}
