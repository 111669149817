import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Table.css";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllCurrencies } from "../../redux/features/currency/currencySlice";
import FlagString from "./FlagString"





const makeStyle=(status)=>{
    return {
        background: '#F4F450',
        color: 'red',
        fontSize: 18,
    }
}

export const BasicTable = ({ city }) => {

  const dispatch = useDispatch()
  const currencies = useSelector((state) => state.currency)

  React.useEffect(() => {
    dispatch(getAllCurrencies(city))
  }, [dispatch, city])



  return (
    currencies.loading ? (
      <Spin className='content-center text-center md:w-1/2' />
    ) :
    !currencies.currencies.length ? (<div className="text-xl text-center bg-yellow-300 rounded-xl p-20">Валюти відсутні</div>):
      <div className="Table content-center text-center">
      <h3 className='p-3 mb-0 bg-yellow-100 rounded-xl content-center text-center' ><b>АКТУАЛЬНИЙ КУРС НА {currencies.currencies[0].date_update}</b></h3>
        <TableContainer
          component={Paper}
          style={{ boxShadow: "10px 13px 20px 0px #80808029", paddingBottom: "10px"}}
        >
          <Table sx={{ minWidth: 200}} aria-label="simple table">
            <TableHead sx={{ fontWeight: 400}} className='bg-yellow-50'>
              <TableRow>
                <TableCell align="center"><span className="text-base">ВАЛЮТА</span></TableCell>
                <TableCell align="center"><span className="text-base">КУПІВЛЯ</span></TableCell>
                <TableCell align="center"><span className="text-base">ПРОДАЖ</span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }} className='bg-yellow-50 content-center text-center body'>
              {currencies.currencies.map((currency, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    <div className="pl-5" >{currency.title}</div>
                  </TableCell>
                  <TableCell align="center">
                    <div  className="status" style={makeStyle(currency.sell_value)}>{currency.sell_value.$numberDecimal}</div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="status" style={makeStyle(currency.buy_value)}>{currency.buy_value.$numberDecimal}</div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <p className='p-3  bg-yellow-100 rounded-b-xl content-center text-center' >‼️ Актуальний <b>курс на купівлю / продаж</b> долара нового зразка <b>(СИНЬОГО)</b> уточнюйте в особистих повідомленнях або по телефону ‼️</p> */}
      </div>
  );
}