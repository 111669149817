import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

const initialState = {
    loading: false,
    message: '',
}

export const createOrder = createAsyncThunk(
    'order/createOrder',
    async (params) => {
        try {
            const { data } = await axios.post('/orders/add', params)
            return data
        } catch (error) {
            console.log(error)
        }
    },
)



export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: {
        
        [createOrder.pending]: (state) => {
            state.loading = true
        },
        [createOrder.fulfilled]: (state, action) => {
            state.loading = false
            state.message = action.payload.message
        },
        [createOrder.rejected]: (state) => {
            state.loading = false
        },
    },
})

export default orderSlice.reducer