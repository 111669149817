import React from 'react';

const Services = () => (
  <div>

<div className='lg:flex  content-center text-center justify-center '>



{/* <div className='bg-white rounded-xl content-center text-center p-3 lg:w-72 my-10 md:mx-5 md:my-0 2xl:w-96 2xl:mx-10 2xl:my-0'>
  <div className='rounded-xl'>
  <img className='rounded-xl' src="/img/obmin.png" alt="" />
  </div>
  <p className=' my-2 mb-3 2xl:text-lg text-slate-700'>Обмін зеленого (звичайного) долару на долар нового зразка</p>
</div> */}

{/* </div>
  
  <div className='lg:flex  content-center text-center justify-center '>
    for 3 in row class xl:ml-20 2xl:ml-0 2xl:flex content-center text-center justify-center */}

  <div className='lg:flex'>
  <div className='bg-white rounded-xl content-center text-center p-3  lg:w-72 my-10 md:mx-5 md:my-0 2xl:w-96 2xl:mx-10 2xl:my-5'>
    <div className='rounded-xl'>
    <img className='rounded-xl' src="/img/shvydko.png" alt="" />
    </div>
    <p className=' my-2 mb-3 2xl:text-lg text-slate-700'>Обмін валют вигідно, безпечно та швидко. Наші відділення присутні в усіх районах міста</p>
  </div>

  <div className='bg-white rounded-xl content-center text-center p-3  lg:w-72 my-10 md:mx-5 md:my-0 2xl:w-96 2xl:mx-10 2xl:my-5'>
  <div className='rounded-xl'>
  <img className='rounded-xl' src="/img/count.png" alt="" />
  </div>
  <p className=' my-2 mb-3 2xl:text-lg text-slate-700'>Перевірка валюти на справжність та надання послуги перерахунку</p>
  </div>

  {/* <div className='bg-white rounded-xl content-center text-center p-3 lg:w-72 my-10 md:mx-5 md:my-0 2xl:w-96 2xl:mx-10 2xl:my-5'>
    <div className='rounded-xl'>
    <img className='rounded-xl' src="/img/damage.png" alt="" />
    </div>
    <p className=' my-2 mb-3 2xl:text-lg text-slate-700'>Прийом старих, пошкоджених та зношених купюр з МІНІМАЛЬНОЮ комісією</p>
  </div> */}
  

  <div className='bg-white rounded-xl content-center text-center p-3 lg:w-72 my-10 md:mx-5 md:my-0 2xl:w-96 2xl:mx-10 2xl:my-5 '>
    <div className='rounded-xl'>
    <img className='rounded-xl' src="/img/multycur.png" alt="" />
    </div>
    <p className=' my-2 mb-3 2xl:text-lg text-slate-700'>USD, EUR, PLN, GBP, CZK, CHF.</p>
  </div>
  </div>

  </div>

  

  </div>
);

export default Services;
