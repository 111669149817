import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://exchanger-service.herokuapp.com/api/v1',
})

export const getUserIP = async () => {
    try {
      const response = await axios.get(`https://ipapi.co/json/`);
      return response.data.ip;
    } catch (error) {
      console.error('Error retrieving user IP:', error);
      return null;
    }
  };


    export const trackIPInGA4 = async (userIP) => {
    try {
       
        const clientIdPromise = new Promise(resolve => {
            window.gtag('get', 'G-9L54G97J5S', 'client_id', resolve)
          });

          
          const clientId = clientIdPromise.then((clientId_)=>{
            console.log('clientId',clientId_)
            return clientId_;
          });

        if(clientId) {
        await axios.post(
        `https://www.google-analytics.com/mp/collect?measurement_id=G-9L54G97J5S&api_secret=naZnpAqzR3CDmoEQTcsCoA`,
        {
            client_id: clientId,
            events: [{
                name: 'user_ip',
                params: {ip: userIP,},
              }]
        }
        
        );
    }
        console.log('IP tracked in GA4');
    } catch (error) {
        console.error('Error tracking IP in GA4:', error);
    }
    };

export default instance