import React from 'react'
import { NavBar } from './NavBar'
import { ConfigProvider } from 'antd';
import { getUserIP, trackIPInGA4 } from '../utils/axios';
import ReactGA from 'react-ga';
ReactGA.initialize('G-9L54G97J5S');
ReactGA.pageview(window.location.pathname + window.location.search);


export const LayoutCover = ({ children }) => {

  const [theme, setTheme] = React.useState({
    token: {
      colorPrimary: '#E93424',
      sizeUnit: 6,
      controlHeight: 40,
    },
  });

  React.useEffect(() => {

    const trackUserIP = async () => {
      const userIP = await getUserIP();
      console.log('User IP:', userIP);
      ReactGA.event({
        category: 'log',
        action: 'log created',
        label: userIP
      });
      // if (userIP) {
      //   trackIPInGA4(userIP);
      // }
      
    };

    trackUserIP();


    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        setTheme({
          token: {
            colorPrimary: '#E93424',
            sizeUnit: 4,
            controlHeight: 30,
          },
        });
      } else if (windowWidth < 1280) {
        setTheme({
          token: {
            colorPrimary: '#E93424',
            sizeUnit: 5,
            controlHeight: 30,
      
          },
        });
      } else {
        setTheme({
          token: {
            colorPrimary: '#E93424',
            sizeUnit: 6,
            controlHeight: 40,
          },
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  return (
  <React.Fragment>
    <ConfigProvider
     theme={theme}
    >
    <div className='container mx-auto 2xl:pl-16 2xl:pr-16'>
        <NavBar />
        <div className='md:pr-12 md:pl-16 2xl:pr-0 2xl:pl-0'>
          {children}
        </div>
    </div>
    </ConfigProvider>
  </React.Fragment>
  )
}
