import React from 'react'
import { Dropdown, Space } from 'antd';

export const TelegramLogoDropdown = () => {
    const items = [
        {
          label: <a  href="https://t.me/obmen_kremenchuk" target="_blank" rel="noopener noreferrer">оператор 1</a>,
          key: '0',
        },
        {
          label: <a href="https://t.me/obmenkremenchuk" target="_blank" rel="noopener noreferrer">оператор 2</a>,
          key: '1',
        },
      ];

  return (
   <Dropdown
        menu={{
            items,
            }}
        trigger={['click']}>
            <a href='/' onClick={(e) => e.preventDefault()}>
                <Space>
                <img src="/img/telegram_icon.png" alt="tg_icon" className=' m-2'/>
                </Space>
            </a>
    </Dropdown>
  )
}
