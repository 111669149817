import React from 'react';
import data from '@emoji-mart/data'
import { init } from 'emoji-mart'

init({ data })


function FlagString(props) {
  const flagRegex = /([\uD800-\uDBFF][\uDC00-\uDFFF])/g;
  const flagMatches = props.value.match(flagRegex);

  if (!flagMatches) {
    return props.value;
  }

  const flagComponents = flagMatches.map((match, index) => {
    return <em-emoji id={match}></em-emoji>;
  });

  const stringParts = props.value.split(flagRegex);

  return (
    <>
      {stringParts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {flagComponents[index]}
        </React.Fragment>
      ))}
    </>
  );
}

export default FlagString;
