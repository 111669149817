import React from 'react'
import { Button } from 'antd';

export const Improveus = () => {
  return (
    <div >
       <div className='mb-10 xl:flex mt-7 p-4 rounded-xl content-center bg-yellow-100 text-center 2xl:px-40 '>
            <p className=' xl:w-1/2 p-3 lg:pl-10 xl:pl-20 2xl:pl-10 lg:text-base xl:text-lg  2xl:text-xl content-center text-center'><b>Ми хочемо розвиватися та не стояти на місці!</b></p>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSe7Y2NhA0pzF3lh0a_vQ31Va0MNT76rRy-tgraPdAXKuIZsdQ/viewform?usp=sf_link' target="_blank" rel="noopener noreferrer" className='md:w-1/2'><Button className='2xl:h-12 2xl:w-80 xl:h-12 xl:w-80 lg:h-10 lg:w-80 lg:ml-12 xl:ml-20 2xl:ml-10 md:text-lg md:h-10' type='primary' danger><b>Запропонувати нову послугу</b></Button></a>
        </div>
    </div>
  )
}

