import React from 'react'
import { TelegramLogoDropdown } from './TelegramLogoDropdown'
import { ViberLogoDropDown } from './ViberLogoDropdown.jsx'

export const Contacts = () => {
  return (
    <div className='flex justify-center md:justify-end items-center'>
        <div className='w-32 lg:w-28 text-sm lg:text-xs xl:w-32 xl:text-sm'>
            <p ><a href='tel:+380980245894'>+380 98 024 5894</a></p>
            <p><a href='tel:+380675409020'>+380 67 540 9020</a></p>
        </div>
        <div className='flex justify-center items-center'>
        <TelegramLogoDropdown />
        <ViberLogoDropDown />
        </div>
    </div>
  )
}
